<template>
    <nav class="navbar">
        <ul class="main-menu">
            <li v-for="item in menu" :key="item.slug" class="menu-item">
                <span :ref="item.slug">
                    <router-link :to="'/'+item.slug">
                    {{ cutTitle(item.title) }}
                </router-link>
                </span>
            </li>
        </ul>
        <span class="target" :style="decorStyle"></span>
    </nav>
</template>

<script>
export default {
    name: "TheMenu",
    props: {menu: Array, menuSlug: String, shown: Boolean},

    data() {
        return {
            decorStyle: {
                width: 0,
                left: 0
            }
        }
    },

    mounted() {
        window.addEventListener('resize', this.windowResize)
    },

    destroyed() {
        window.removeEventListener('resize', this.windowResize)
    },
    activated() {
        this.countStyle()
    },

    watch: {
        menuSlug: {
            handler: 'watchStyle',
            immediate: true
        },

        shown: {
            handler: 'watchStyle',
            immediate: true
        },
    },

    methods: {
        cutTitle(str) {
            if (this.$device.mobile) {
                let arr = str.split(" ")
                return arr[0]
            }
            return str
        },

        watchStyle(val) {
            this.countStyle()
        },

        countStyle() {
            let param = {}

            if (!this.$refs[this.menuSlug]) {
                param = {
                    width: 0,
                    left: this.decorStyle.left ? `100%` : 0
                }
            } else {
                let el = this.$refs[this.menuSlug][0] || this.$refs[this.menuSlug][0].$el
                param = {
                    width: `${el.offsetWidth}px`,
                    left: `${el.getBoundingClientRect().left}px`
                }
            }

            this.decorStyle = param
        },

        windowResize() {
            this.$nextTick(function () {
                this.countStyle()
            })
        },
    }
}
</script>

<style scoped>

</style>
