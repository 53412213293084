<template>

    <the-menu v-if="!hide" :menu="menu" :menu-slug="menuSlug" :shown="transitionEntered"></the-menu>

</template>

<script>
    import store from "../store";
    import TheMenu from "../components/TheMenu";
    import {EventBus} from "../main";

    export default {
        name: "NavbarOverlay",
        components: {TheMenu},
        props: {menuSlug: String, transitionEntered: Boolean},
        data() {
            return {
                hide: false
            }
        },
        computed: {
            menu() {
                return store.menu
            }
        },
        mounted() {
            EventBus.$on('showMenuPlayer', payload => {
                this.hide = false
            })
            EventBus.$on('hideMenuPlayer', payload => {
                this.hide = true
            })
        }
    }
</script>

<style scoped>
</style>
